import '../scss/index.scss'

var mobile;

document.addEventListener('DOMContentLoaded', function() {
  
  // const slideShow = document.querySelector('.swiper');

  // MOBILE DETECT
  function checkMobile(){
    if(window.innerWidth <= 1024 ){
      mobile = 1;
    }else{
      mobile = 0;
      // Cursor();
    }
    return mobile;
  }
  checkMobile();

  window.addEventListener('resize', function(e) {
    checkMobile();
  });

  // About click
  document.querySelector('.btn-about').addEventListener('click', function(e) {
    e.preventDefault()
    document.body.classList.toggle('about-active')
  });
  document.querySelector('.about-closer').addEventListener('click', function(e) {
    e.preventDefault()
    document.body.classList.remove('about-active')
  });
  window.addEventListener('scroll', function(e) {
    if(mobile == 0 && document.body.classList.contains('about-active'))
    document.body.classList.remove('about-active')
  });


  // SWIPERS
  const swipers = document.querySelectorAll('.swiper');
  const sliders = [];

  for (let i = 0; i <= swipers.length; i++) { 
    sliders[i] = new Swiper(swipers[i], {
      slidesPerView: 'auto',
      centeredSlides: false,
      loop: true,
      // loopedSlides: 3,
      // navigation: {
      //   nextEl: ".next",
      //   prevEl: ".prev",
      // },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'fraction',
      // },
      on: {
        beforeInit: function(){
          let slides = this.el.querySelectorAll('.swiper-slide');
          if(slides.length == 2 || slides.length == 3){
            let wrapper = this.el.querySelector('.swiper-wrapper');
            let wrapperContent = wrapper.innerHTML;
            wrapper.innerHTML = wrapperContent + wrapperContent;
          }
        },
        init: function () {
          let count = this.el.querySelector('.swiper-slide-active').dataset.snum + '/' + this.el.dataset.stotal;
          this.el.querySelector('.swiper-pagination').innerHTML = count;
        },
        slideChangeTransitionEnd: function () {
          if(mobile == 1){
            loadImg(this.el.querySelector('.swiper-slide-next'))
          }
          let count = this.el.querySelector('.swiper-slide-active').dataset.snum + '/' + this.el.dataset.stotal;
          this.el.querySelector('.swiper-pagination').innerHTML = count;
        },
      },
    });
  }

  // Swiper click CLOSE
  document.querySelectorAll('.closer').forEach(closer => {
    closer.addEventListener('click', function(e) {
      if(closer.parentNode.classList.contains('full-screen')){
        closer.parentNode.classList.remove('full-screen');
        closer.parentNode.classList.add('small');
      }
    });  
  })

  // Swiper click NEXT
  document.querySelectorAll('.next').forEach(next => {
    next.addEventListener('click', function(e) {
      let num = next.parentNode.dataset.num;
      if(next.parentNode.classList.contains('full-screen') || mobile == 1){
        sliders[num].slideNext();
        window.scrollTo({
          top: next.parentNode.parentNode.offsetTop + 1,
          behavior: 'smooth',
        });
      }else{
        let slides = next.parentNode.querySelectorAll('.swiper-slide')
        slides.forEach(slide => {
          loadImg(slide)
        })

        growSwiper(sliders[num]); 
      }
    });
  })

  // Mobile click go next
  // if(mobile == 1){
  //   document.querySelectorAll('.swiper').forEach(swp => {
  //     swp.addEventListener('click', function(e) {
  //       let num = swp.dataset.num;
  //       if(e.clientX >= (window.innerWidth/2)){
  //         sliders[num].slideNext();
  //       }else{
  //         sliders[num].slidePrev();
  //       }
        
  //     });
  //   })
  // }
  
  // Swiper click PREV
  document.querySelectorAll('.prev').forEach(prev => {
    prev.addEventListener('click', function(e) {
      let num = prev.parentNode.dataset.num;
      if(prev.parentNode.classList.contains('full-screen') || mobile == 1){
        sliders[num].slidePrev();
        window.scrollTo({
          top: prev.parentNode.parentNode.offsetTop + 1,
          behavior: 'smooth',
        });
      }else{
        let slides = prev.parentNode.querySelectorAll('.swiper-slide')
        slides.forEach(slide => {
          loadImg(slide)
        })

        growSwiper(sliders[num])
      }
    });
    
  })

  function loadImg(slide){
    if(!slide.classList.contains('loaded'))
    if(slide.classList.contains('mp4')){
      let video = slide.querySelector('video');
      if(!video.src)
      slide.querySelector('video').src = slide.querySelector('video').dataset.src;
    }else{
      let img = slide.querySelector('img');
      if(img.dataset.srcset != undefined){
        img.srcset = img.dataset.srcset;
      }
    }
    slide.classList.add('loaded')
  }

  // Projects List click
  document.querySelectorAll('.projects-list a').forEach(project => {
    project.addEventListener('click', function(e) {
      e.preventDefault()
      let num = project.dataset.p;
      growSwiper(sliders[num]);
    });
  })

  function growSwiper(slider){
    let sectionTop = slider.el.parentNode.offsetTop;
    let toScroll = sectionTop + 1;
    if(!slider.el.classList.contains('full-screen')){
      if(document.querySelector('.full-screen')){
        let sliderFull = document.querySelector('.full-screen');
        let dif = (window.innerHeight * 1.01) - slider.el.parentNode.offsetHeight;
        
        sliderFull.classList.remove('full-screen');
        sliderFull.classList.add('small');
        
        if(sectionTop > sliderFull.parentNode.offsetTop){
          toScroll = toScroll - dif;
        } 
      }

      console.log('to Scroll = ' + toScroll )

      window.scrollTo({
        top: toScroll,
        behavior: 'smooth',
      });
      
      slider.el.classList.add('full-screen');
      slider.el.classList.remove('small');
    }
  }

  // OBSERVER
  var sections = document.querySelectorAll('section');
  let options = { rootMargin: '-29% 0px -70% 0px'};
  let io = new IntersectionObserver(observeSections, options);
  let projectList = document.querySelectorAll('.projects-list > a')
  sections.forEach((section) => {
    io.observe(section);
  });

  function observeSections(entries, obs){
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        // Add 'active' class if observation target is inside viewport
        let pNum = entry.target.querySelector('.swiper').dataset.num;
        projectList[pNum].classList.add('p-active');
      } else {
        // Remove 'active' class otherwise
        let pNum = entry.target.querySelector('.swiper').dataset.num;
        projectList[pNum].classList.remove('p-active');
      }
    });
  }

  document.querySelectorAll('img').forEach((image) => {
    if(imgOK(image)){

      console.log('IMG LOADED')
    }
    
  });

  function imgOK(img) {
    if (!img.complete) {
        return false;
    }
    if (typeof img.naturalWidth != "undefined" && img.naturalWidth == 0) {
        return false;
    }
    return true;
  }
  

});
